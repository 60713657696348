/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';

import HeroSlide from './heroSlide';

export default function HeroCarousel({ setSite }) {
    return (
      <div className="hero-container">
        <CarouselProvider
          className="hero-content-box"
          infinite
          isIntrinsicHeight
          totalSlides={4}
          interval={5000}
          isPlaying
        >
          <Slider>
            <Slide index={0}>
              <HeroSlide
                // setSite={setSite}
                title="Time to Enroll!"
                img="/assets/img/FINRED-WebsiteImage-Nov2024-H-DCFSA-Enroll.jpg"
                imgAlt="Service member holding child"
                buttonLink="https://www.fsafeds.gov/"
                buttonText="Reap Tax Benefits"
              >
                <p>
                It’s your once-a-year opportunity to sign up for a Dependent Care FSA (DCFSA). Enroll by Dec. 9.
                </p>
              </HeroSlide>
            </Slide>

            <Slide index={1}>
              <HeroSlide
                title="Got Questions about DCFSA?"
                img="/assets/img/FINRED-WebsiteImage-Nov2024-H-DCFSASpouse.jpg"
                imgAlt="Military family sitting near tree"
                buttonLink="/Benefits/DCFSA/FAQ"
                buttonText="Get the Facts"
                // buttonCentered
              >
                <p>
                We have answers in our Dependent Care Flexible Spending Account (DCFSA) FAQ page!
                </p>
              </HeroSlide>
            </Slide>

            <Slide index={2}>
              <HeroSlide
                // setSite={setSite}
                title="A Compensation Conversation"
                img="/assets/img/FINRED-CompVideo-Oct2024-HomepageBanner.jpg"
                imgAlt="Blue background “Is your life a MilLife?”"
                buttonLink="/video/ACompensationConversation"
                buttonText="View the Video"
              >
                <p>
                Active-duty service members received more benefits and their largest raise in 20 years in 2024.
                </p>
              </HeroSlide>
            </Slide>

            <Slide index={3}>
              <HeroSlide
                // setSite={setSite}
                title="2024 Year-End Checklist"
                img="/assets/img/FINRED-WebsiteImage-Nov2024-H-EOYDDP.jpg"
                imgAlt="Clock, calendar, money and checkmark icon"
                buttonLink="/Money/NewYearChecklist"
                buttonText="See the Checklist"
              >
                <p>
                Use this handy nine-point checklist now to organize your finances and prepare for 2025.
                </p>
              </HeroSlide>
            </Slide>
          </Slider>
          <ButtonBack className="move-button left"><span className="fa fa-angle-left" /></ButtonBack>
          <ButtonNext className="move-button right"><span className="fa fa-angle-right" /></ButtonNext>
          <DotGroup className="hero-dots" />
        </CarouselProvider>
      </div>
    );
}
